import { default as CoeDs6GhbTjWBMeta } from "/codebuild/output/src116989847/src/toppan-voice-recorder-frontend/pages/Create/Coe.vue?macro=true";
import { default as EnvironmentalSound4km7IXyx6YMeta } from "/codebuild/output/src116989847/src/toppan-voice-recorder-frontend/pages/Create/EnvironmentalSound.vue?macro=true";
import { default as PointswGq0cO2tlcMeta } from "/codebuild/output/src116989847/src/toppan-voice-recorder-frontend/pages/Create/Points.vue?macro=true";
import { default as RecordTestFq0gJ7XBzLMeta } from "/codebuild/output/src116989847/src/toppan-voice-recorder-frontend/pages/Create/RecordTest.vue?macro=true";
import { default as TextdKL5FbSRpkMeta } from "/codebuild/output/src116989847/src/toppan-voice-recorder-frontend/pages/Create/Text.vue?macro=true";
import { default as FaqL5h8gPxVF4Meta } from "/codebuild/output/src116989847/src/toppan-voice-recorder-frontend/pages/Faq.vue?macro=true";
import { default as SigninJ2vXAmzX4RMeta } from "/codebuild/output/src116989847/src/toppan-voice-recorder-frontend/pages/Signin.vue?macro=true";
import { default as indexH03lDwPx13Meta } from "/codebuild/output/src116989847/src/toppan-voice-recorder-frontend/pages/index.vue?macro=true";
export default [
  {
    name: CoeDs6GhbTjWBMeta?.name ?? "Create-Coe",
    path: CoeDs6GhbTjWBMeta?.path ?? "/Create/Coe",
    meta: CoeDs6GhbTjWBMeta || {},
    alias: CoeDs6GhbTjWBMeta?.alias || [],
    redirect: CoeDs6GhbTjWBMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src116989847/src/toppan-voice-recorder-frontend/pages/Create/Coe.vue").then(m => m.default || m)
  },
  {
    name: EnvironmentalSound4km7IXyx6YMeta?.name ?? "Create-EnvironmentalSound",
    path: EnvironmentalSound4km7IXyx6YMeta?.path ?? "/Create/EnvironmentalSound",
    meta: EnvironmentalSound4km7IXyx6YMeta || {},
    alias: EnvironmentalSound4km7IXyx6YMeta?.alias || [],
    redirect: EnvironmentalSound4km7IXyx6YMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src116989847/src/toppan-voice-recorder-frontend/pages/Create/EnvironmentalSound.vue").then(m => m.default || m)
  },
  {
    name: PointswGq0cO2tlcMeta?.name ?? "Create-Points",
    path: PointswGq0cO2tlcMeta?.path ?? "/Create/Points",
    meta: PointswGq0cO2tlcMeta || {},
    alias: PointswGq0cO2tlcMeta?.alias || [],
    redirect: PointswGq0cO2tlcMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src116989847/src/toppan-voice-recorder-frontend/pages/Create/Points.vue").then(m => m.default || m)
  },
  {
    name: RecordTestFq0gJ7XBzLMeta?.name ?? "Create-RecordTest",
    path: RecordTestFq0gJ7XBzLMeta?.path ?? "/Create/RecordTest",
    meta: RecordTestFq0gJ7XBzLMeta || {},
    alias: RecordTestFq0gJ7XBzLMeta?.alias || [],
    redirect: RecordTestFq0gJ7XBzLMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src116989847/src/toppan-voice-recorder-frontend/pages/Create/RecordTest.vue").then(m => m.default || m)
  },
  {
    name: TextdKL5FbSRpkMeta?.name ?? "Create-Text",
    path: TextdKL5FbSRpkMeta?.path ?? "/Create/Text",
    meta: TextdKL5FbSRpkMeta || {},
    alias: TextdKL5FbSRpkMeta?.alias || [],
    redirect: TextdKL5FbSRpkMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src116989847/src/toppan-voice-recorder-frontend/pages/Create/Text.vue").then(m => m.default || m)
  },
  {
    name: FaqL5h8gPxVF4Meta?.name ?? "Faq",
    path: FaqL5h8gPxVF4Meta?.path ?? "/Faq",
    meta: FaqL5h8gPxVF4Meta || {},
    alias: FaqL5h8gPxVF4Meta?.alias || [],
    redirect: FaqL5h8gPxVF4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src116989847/src/toppan-voice-recorder-frontend/pages/Faq.vue").then(m => m.default || m)
  },
  {
    name: SigninJ2vXAmzX4RMeta?.name ?? "Signin",
    path: SigninJ2vXAmzX4RMeta?.path ?? "/Signin",
    meta: SigninJ2vXAmzX4RMeta || {},
    alias: SigninJ2vXAmzX4RMeta?.alias || [],
    redirect: SigninJ2vXAmzX4RMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src116989847/src/toppan-voice-recorder-frontend/pages/Signin.vue").then(m => m.default || m)
  },
  {
    name: indexH03lDwPx13Meta?.name ?? "index",
    path: indexH03lDwPx13Meta?.path ?? "/",
    meta: indexH03lDwPx13Meta || {},
    alias: indexH03lDwPx13Meta?.alias || [],
    redirect: indexH03lDwPx13Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src116989847/src/toppan-voice-recorder-frontend/pages/index.vue").then(m => m.default || m)
  }
]